export const apiList = {
  // AUTH
  BaseUrlApi: false
    ? "https://api.instatrainme.com/api"
    : "https://devapi.instatrainme.com/api",
  LOGIN: "/auth/admin-login",
  ForgotPassword: "/auth/forgot-password",
  //Dash
  Dashboard: "/admin/dashboard",
  //Booking
  GetBookingList: "/admin/booking-list",
  GetBookingDetailsById: "/admin/booking-detail",
  //Payment
  GetPaymentList: "/admin/get-payment-list",
  //user
  UserAction: "/admin/user-action",
  UserApproval: "/admin/user-approval",
  UserList: "/admin/user-list",
  UserDetailsById: "/admin/user-details",
  DeleteUser: "/admin/user-action",

  TrainerLocationPreferenceAction: "/trainer/location-approve-reject",
  TrainerCategoryAction: "/trainer/cat-approve-reject",
  TrainersList: "/admin/trainer-list",
  TrainersDetails: "/admin/trainer-details",
  TrainersDetails_slots: "admin/trainer-details/slots",
  TrainersWeeklyScheduleDetails: "admin/trainer-details/slots/days",
  TrainersDetails1: "/admin/trainer-details_1",
  TrainersDetails2: "/admin/get-review-order-list",

  SendNotifications: "/notification/sent-multiple-notification",
  sendPersonalNotification: "/notification/sent-notification",
  GetNotificationsList: "/notification/get-notification?user_type=3&u_id=0",
  getAppIssueList: "/admin/get-issue-list",
  //FAQ
  AddFAQs: "/admin/addfaq",
  FAQsList: "/admin/get-faq-list",
  //stripe
  updateCharge: "/admin/edit-charge",
  getStripeCharge: "/admin/get-charge",

  // AWS
  getAwsS3key: "/admin/get-key",
  updateAwsS3key: "/admin/edit-key",
  getSignedURL: "/auth/get-url",

  // Training plan Details
  getTrainingPlanDetailsById: "/training-plan/get-by-id",
  getTrainerPlans: "/admin/get-plans-days",
  getUserProgress: "/admin/get-workout-logs",

  //Notification
  FCMTokenUpadate: "/notification/add-update-fcmtoken",

  //manage New Reqest
  getAllNewRequest: "/notification/get-admin-notification",
  getUnreadCount: "/notification/get-unread-count",
  updateToRead: "/notification/get-read",
};
